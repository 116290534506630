* {
  font-family: 'Rubik', sans-serif;
}
.navbar {
  margin-bottom: 20px;
  background-color: #9ba881;
}
.day {
  border: 1px solid rgb(58, 58, 58);
  width: 65px;
  display: inline-block;
  text-align: left;
}
.wdit {
  border: 2px dotted rgb(58, 58, 58);
  width: 65px;
  display: inline-block;
}
.dayContent {
  margin-bottom: 30px;
  margin: none;
}
.invis {
  opacity: 0%;
  padding: 5px;
}
.scheduledAppointment {
  background-color: rgb(222, 220, 220);
  opacity: 80%;
  border-radius: 10px;
  text-align: center;
}
.tile {
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  border-color: azure;
}
:hover.tile {
  background-color: rgb(196, 196, 196);
}
.react-calendar__navigation__arrow {
  border-color: azure;
  margin: 0 1.5rem;
  background-color: white;
  padding: 5px 35px;
  border-radius: 10px;
}
:hover.react-calendar__navigation__arrow {
  background-color: rgb(196, 196, 196);
}
.react-calendar__navigation {
  display: block;
  text-align: center;
}
.slide {
  height: 225px;
}
abbr {
  margin-bottom: 50px;
}
.center {
  text-align: center;
}
.SchApp {
  margin-top: 100px;
}
.brdr {
  border-radius: 10px;
}
.m1 {
  padding: 24px 0px;
}
.card-body {
  margin-bottom: 10px;
}
.card {
  margin-bottom: 20px;
}
footer {
  background-color: #53533c;
  position: relative;
  bottom: 0;
  padding: 10px;
  width: 100%;
  color: rgb(211, 211, 211);
}
.green {
  background-color: green;
}
.ap {
  width: 50px;
}
.slidetxt {
  color: rgb(103, 143, 24);
  font-size: 18px;
  visibility: hidden;
}
.cclr1 {
  background-color: #adab9f!important;
}
.cclr2 {
  background-color: #b4ad7a!important;
}
.pricing {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
th {
  padding: 15px;
}
.lgt {
  font-size: 25px;
}
.fr {
  display: inline;
  float: right;
  text-decoration: underline;
}
.pricingChart {
  margin-bottom: 30px!important;
}
.contact {
  font-size: 20px;
}
.callorem {
  font-size: 20px;
}
.homeHead {
  font-size: 25px;
  display: inline;
}
.navImg {
  width: 80px;
  display: inline;
  border-radius: 20px;
  margin: 0px 15px 0px 0px;
}
.cclr3 {
  background-color: #DBE9CD!important;
}
.giftcard-title {
  margin-top: 15px;
  font-size: 18px;
  text-decoration: underline;
  color: black;
}
.giftcard-title:hover {
  color: #b4ad7a;
  cursor: pointer;
}

.ddLink {
  padding: 5px;
  display: block;
  text-decoration: none;
  color: black;
}
:hover.ddLink {
  background-color: rgb(181, 181, 181);
  color: #53533c;
}
.dropdown-menu {
  width: 300px;
}
.map {
  border-radius: 30px!important;
}
.mapB {
  padding: 10px;
  background-color: #53533c;
  border-radius: 30px;
}
.rwlink {
  color: white!important;
}
.rwlink:hover {
  color: #9ba881!important;
}

.aboutImg {
  padding-bottom: 20px;
  border-radius: 50px;
}

@media (min-width: 500px) {
  .slide {
    height: 300px;
}
}
@media (min-width: 768px) {
  .navImg {
    width: 120px;
  }
  .slide {
    height: 400px;
  }
  .slidetxt {
    visibility: visible;
  }
  .contact1 {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .navImg {
    width: 160px;
  }
  .day {
    width: 140px;
  }
  .wdit {
    width: 140px;
  }
  .invis {
    margin: 20px;
  }
  .slide {
    height: 600px;
  }
  .contact1 {
    font-size: 17px;
  }
  .dd {
    margin-right: 100px!important;
  }
}
@media (min-width: 1400px) {
  .slide {
    height: 750px;
  }
  .dd {
    margin-right: 50px!important;
  }
}